import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { map } from 'rxjs/operators';
import { RequestService } from './request.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public platform = 'web';
  // public platform = 'mobile';

  constructor(
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private translate: TranslateService
  ) {
  }

  errorImage(img) {
    img.src = 'assets/not-found.png';
  }

  public fileChange(event): Promise<string | ArrayBuffer> {
    return new Promise((res, rej) => {
      if (event?.target?.files?.length) {
        const reader = new FileReader();
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          res(reader.result);
        };
      }
    })
  }

  handleError(error: any): string {
    if (!error) {
      return null;
    }

    switch (error.status) {
      case 0:
        return this.translate.instant('0');
      case 500:
        return this.translate.instant('500');
      default:
        return this.translate.instant(error.error);
    }
  }

  public loading(callback: any) {
    this.loadingCtrl.create({
      message: this.translate.instant('PLEASE_WAIT')
    }).then(loading => {
      loading.present().then(_ => callback(loading));
    });
  }

  public toast(message: string, replace: string | null, duration: number, error?: Error) {
    this.toastCtrl.create({
      message: this.handleError(error) || this.translate.instant(message).replace('&replace&', replace),
      duration
    }).then(toast => toast.present());
  }

  changeImg(event, user) {
    user.avatar = 'https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569__340.jpg';
  }

  public showError(form, field): boolean {
    return field.invalid && (field.dirty || field.touched || (form && form.submitted)) && field.errors && field.errors.required;
  }

  getErrorMessage(field: AbstractControl): string {
    if (!field) {
      return;
    }

    if (field.hasError('required')) {
      return this.translate.instant('REQUIRED_FIELD');
    }

    if (field.hasError('email')) {
      return this.translate.instant('INVALID_EMAIL');
    }

    if (field.hasError('pattern')) {
      return this.translate.instant('INVALID_PATTERN');
    }

    if (field.hasError('passwordMatch')) {
      return this.translate.instant('PASSWORD_MATCH');
    }

    return this.translate.instant('REQUIRED_FIELD');
  }

  passwordMatch(password: AbstractControl, confirmPassword: AbstractControl): boolean {
    if (confirmPassword.errors && !confirmPassword.errors.passwordMatch) {
      return;
    }

    if (password.value !== confirmPassword.value) {
      confirmPassword.setErrors({ passwordMatch: true });
      return true;
    }

    confirmPassword.setErrors(null);
    return false;
  }

  public resetForm(reactiveForm: FormGroup, formRef: FormGroupDirective): void {
    reactiveForm.reset();
    reactiveForm.markAsUntouched();
    reactiveForm.markAsPristine();
    reactiveForm.updateValueAndValidity();
    formRef.resetForm();
  }

  public handleObsError(err: any) {
    let errorMessage: string;

    switch (err.status) {
      case 0:
        errorMessage = this.translate.instant('0');
        break;
      case 422:
        errorMessage = this.translate.instant('422');
        break;
      case 500:
        errorMessage = this.translate.instant('500');
        break;
    }

    return throwError(errorMessage);
  }

  public imageURLPipe() {
    return map((e: any) => {
      return {
        count: e.count,
        data: e.data.map(b => {
          return {
            ...b,
            image: environment.baseURL + '/' + b.image
          }
        })
      }
    })
  }

  public badgeSelectedPipe() {
    return map((response: {
      availablePoints: number,
      badges: Array<{ id: number, name: string, image: string, requiredPoints: number, selected: false, idOrganization: number }>
    }) => {
      return {
        ...response,
        badges: response.badges.map(badge => {
          return {
            ...badge,
            select: false
          }
        })
      };
    })
  }
}
