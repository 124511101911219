import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CategoriesType, CheckinTypes,
  FavoritePoiType,
  FeatureObjType,
  ImageResponseType, Logbook, MyQrType,
  OrganizationsType,
  ServicesType,
  UsersType,
  UserType
} from '../models/request.m';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { DefaultResponseType, FriendRequestType, FriendType } from '../models/request.m';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  public baseURL = environment.baseURL;
  private currentUserSubject: BehaviorSubject<UserType>;
  public currentUser: Observable<UserType>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    this.currentUserSubject = new BehaviorSubject<UserType>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  login(body: { email: string, password: string }): Observable<UserType> {
    return this.http.post<UserType>(`login`, body)
      .pipe(map((user: any) => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  refreshToken(refreshToken: string): Observable<UserType> {
    return this.http.post<UserType>('refresh-token', { refreshToken })
      .pipe(map((user: any) => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);

        return user;
      }));
  }

  updateUser(body: any): Observable<UserType> {
    return this.http.patch<UserType>(`api/users/${this.currentUserValue.id}`, body)
      .pipe(map((user: any) => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);

        return user;
      }));
  }

  register(body: { email: string, password: string }): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>('register', body);
  }

  firstSetup(body: { name: string, avatar: string }): Observable<UserType> {
    return this.http.post<UserType>('first-setup', body).pipe(map((user: any) => {
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
      return user;
    }));
  }

  async logout() {
    this.http.post('logout', {});
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    await this.router.navigate(['/login']);
  }

  getFriends(): Observable<FriendType[]> {
    return this.http.get<FriendType[]>(`friends`);
  }

  getFriendRequests(): Observable<FriendRequestType[]> {
    return this.http.get<FriendRequestType[]>(`friends-requests`);
  }

  acceptFriendRequest(id: number): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>(`accept-request`, { id });
  }

  removeFriendRequest(id: number): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>(`remove-request`, { id });
  }

  searchFriends(name: string) {
    return this.http.post(`search-friends`, { name });
  }

  addFriend(id: number) {
    return this.http.post(`add-friend`, { id });
  }

  deleteFriend(id: number) {
    return this.http.post(`delete-friends`, { id });
  }

  getOrganizations(): Observable<OrganizationsType[]> {
    return this.http.get<OrganizationsType[]>(`organizations`);
  }

  addOrganization(name: string) {
    return this.http.post(`organizations`, { name });
  }

  getUserTypes() {
    return this.http.get(`user-types`);
  }

  addUser(email: string,
    name: string,
    idOrganization: number | null,
    idType: number) {
    return this.http.post(`api/users`, { email, name, idOrganization, idType });
  }

  getUsers(limit = 5, offset = 0): Observable<{ data: UsersType[], count: number }> {
    return this.http.get<{ data: UsersType[], count: number }>(`users?limit=${limit}&offset=${offset}`)
      .pipe(
        map(e => {
          return {
            count: e.count,
            data: e.data.map(u => {
              return {
                id: u.id,
                name: u.name,
                email: u.email,
                avatar: u.avatar ? this.baseURL + u.avatar : '/assets/not-found.png',
                type: u.type
              }
            })
          }
        }
        )
      );
  }

  getUserAvailableBadges(mobileUserToken: string) {
    return this.http.get(`api/badges/user-available/${mobileUserToken}`);
  }

  unlockBadges(userQR: string, badges: Array<{ id: number }>) {
    return this.http.post(`api/badges/unlock`, { userQR, badges });
  }

  getOrganizationCategories(): Observable<CategoriesType[]> {
    return this.http.get<CategoriesType[]>('api/categories');
  }

  getOrganizationServices(): Observable<ServicesType[]> {
    return this.http.get<ServicesType[]>('services');
  }

  addCategory(idOrganization: number | null, name: string): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>('api/categories', { idOrganization, name });
  }

  addPoi(features: string, checkinTypes: Array<CheckinTypes>): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>('add-pois', { features, checkinTypes });
  }

  updatePoi(poiId: number, features: string, checkinTypes: Array<CheckinTypes>): Observable<DefaultResponseType> {
    return this.http.patch<DefaultResponseType>('update-poi', { poiId, features, checkinTypes });
  }

  favouritePois(id?: number): Observable<Array<FavoritePoiType>> {
    return this.http.get<Array<FavoritePoiType>>(`favorite-pois/${id ? id : ''}`);
  }

  manageFavoritePois(idPoi: number, action: string): Observable<FavoritePoiType> {
    return this.http.post<FavoritePoiType>(`favorite`, { idPoi, action });
  }

  saveImage(image: string): Observable<ImageResponseType> {
    return this.http.post<ImageResponseType>('save-image', { image });
  }

  getFeatures(url: string): Observable<FeatureObjType> {
    return this.http.get<FeatureObjType>(url);
  }

  getMyQr(): Observable<MyQrType> {
    return this.http.get<MyQrType>('my-qr');
  }

  review(body): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>('review', body);
  }

  passwordReset(email: string, password: string): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>('password-reset', { email, password });
  }

  getCheckinTypes(): Observable<CheckinTypes[]> {
    return this.http.get<CheckinTypes[]>('api/checkin-types');
  }

  getLogbook(type): Observable<Logbook[]> {
    return this.http.get<Logbook[]>(`logbook/${type}`)
      .pipe(
        map(e => {
          return e.map(o => {
            return {
              poi: o.poi,
              username: o.username,
              date: this.datePipe.transform(o.date, 'yyyy-MM-dd HH:MM'),
              image: o.image,
              avatar: o.avatar
            };
          });
        })
      );
  }

  GET_BLOB<T>(route: string) {
    return this.http.get(route, { responseType: 'blob' });
  }

  GET<T>(route: string, op?: any): Observable<T> {
    if (!op) {
      return this.http.get<T>(route);
    }

    return this.http.get<T>(route).pipe(op);
  }

  POST<T, B>(route: string, body: B): Observable<T> {
    return this.http.post<T>(route, body);
  }

  PATCH<T, B>(route: string, body: B): Observable<T> {
    return this.http.patch<T>(route, body);
  }

  DELETE<T>(route: string): Observable<T> {
    return this.http.delete<T>(route);
  }
}
