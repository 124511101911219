import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { RoutingService } from './services/routing.service';
import { RequestService } from './services/request.service';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public appRoutes = {
    root: [
      { key: 'ORGANIZATIONS', name: '', path: '/organizations', icon: 'home-outline' }
    ],
    admin: [
      { key: 'USERS', name: '', path: '/users', icon: 'person-add-outline' },
      { key: 'CATEGORIES', name: '', path: '/categories', icon: 'duplicate-outline' },
      { key: 'OPERATOR_REQUESTS', name: '', path: '/operator-requests', icon: 'person-add-outline' }
    ],
    operator: [
      { key: 'PRODUCTS', name: '', path: '/products', icon: 'bag-add-outline' },
      { key: 'SERVICES', name: '', path: '/services', icon: 'cafe-outline' },
      { key: 'POI', name: '', path: '/poi', icon: 'location-outline' },
      { key: 'EXPORTS', name: '', path: '/exports', icon: 'download-outline' }
    ],
    mobile: [
      { key: 'MAP', name: '', path: '/map', icon: 'map-outline' },
      { key: 'QR', name: '', path: '/qr', icon: 'qr-code-outline' },
      { key: 'JURNAL', name: '', path: '/logbook', icon: 'home-outline' },
      { key: 'REWARDS', name: '', path: '/rewards', icon: 'medal-outline' },
      { key: 'FRIENDS', name: '', path: '/friends', icon: 'people-outline' },
      { key: 'FAVORITE', name: '', path: '/favorites', icon: 'star-outline' },
      { key: 'STATS', name: '', path: '/statistics', icon: 'stats-chart-outline' },
      { key: 'PROFILE', name: '', path: '/profile', icon: 'person-circle-outline' }
    ]
  };

  private $routeSub;

  constructor(
    private menuCtrl: MenuController,
    private router: Router,
    public routing: RoutingService,
    public request: RequestService,
    private translate: TranslateService
  ) {
    this.translate.onDefaultLangChange.subscribe((params: DefaultLangChangeEvent) => {
      for (const key in this.appRoutes) {
        for (const route of this.appRoutes[key]) {
          route.name = this.translate.instant(route.key);
          if (route.path === this.routing.activeRoute) {
            this.routing.activeRouteName = route.name;
          }
        }
      }
    })
  }

  ngOnInit() {
    this.translate.setDefaultLang(this.request.currentUserValue?.lang || 'ro');

    this.$routeSub = this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.routing.activeRouteName = this.routes.find(e => value.urlAfterRedirects.indexOf(e.path) !== -1)?.name;
        this.routing.activeRoute = value.urlAfterRedirects;
      }
    });
  }

  ngOnDestroy() {
    this.$routeSub.unsubscribe();
  }

  public async toggleMenu() {
    await this.menuCtrl.open('side-menu-id');
  }

  get routes() {
    switch (this.request.currentUserValue?.type) {
      case 'root':
        return [...this.appRoutes.root, ...this.appRoutes.admin, ...this.appRoutes.operator, ...this.appRoutes.mobile];
      case 'admin':
        return [...this.appRoutes.admin, ...this.appRoutes.operator, ...this.appRoutes.mobile];
      case 'operator':
        return [...this.appRoutes.operator, ...this.appRoutes.mobile];
      case 'mobile':
        return this.appRoutes.mobile;
      default:
        return [];
    }
  }

  public trackFn(index: number) {
    return index;
  }

  public active(route): boolean {
    return this.routing.activeRoute.indexOf(route.path) !== -1;
  }

  async logout() {
    await this.request.logout();
  }

  get navBar() {
    if (!this.request.currentUserValue) {
      return false;
    }

    return !(this.request.currentUserValue.type === 'mobile' && !this.request.currentUserValue.setup);
  }

  errorImage(img) {
    img.src = 'assets/not-found.png';
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

