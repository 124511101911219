import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {RequestService} from './request.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private request: RequestService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.request.currentUserValue;
    if (currentUser) {
      if (currentUser.type === 'mobile' && !currentUser.setup) {
        await this.router.navigate(['/first-setup'], {queryParams: {returnUrl: state.url}});
        return false;
      }

      return true;
    }

    await this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
