import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { RequestService } from './request.service';
import { switchMap, catchError } from 'rxjs/operators';
import { UserType } from '../models/request.m';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {
  constructor(private request: RequestService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const currentUser = this.request.currentUserValue;

    if (currentUser && currentUser.authToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.authToken}`
        }
      });
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && !request.url.includes('login') && error.status === 401) {
        return this.handle401Error(request, next);
      }

      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    const token = this.request.currentUserValue?.refreshToken;

    if (token)
      return this.request.refreshToken(token).pipe(
        switchMap((user: UserType) => {
          return next.handle(request.clone({
            setHeaders: {
              Authorization: `Bearer ${user.authToken}`
            }
          }));
        }),
        catchError((err) => {
          this.request.logout();
          return throwError(err);
        })
      );
  }
}
