import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {RequestService} from './request.service';

@Injectable({
  providedIn: 'root'
})
export class SetupGuardService implements CanActivate {

  constructor(
    private router: Router,
    private request: RequestService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.request.currentUserValue;

    if (currentUser && !currentUser.setup && currentUser.type === 'mobile') {
      return true;
    }

    await this.router.navigate(['/map']);
    return false;
  }
}
